// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-templates-ferry-route-template-js": () => import("./../../../src/templates/ferry-route-template.js" /* webpackChunkName: "component---src-templates-ferry-route-template-js" */),
  "component---src-templates-landing-template-js": () => import("./../../../src/templates/landing-template.js" /* webpackChunkName: "component---src-templates-landing-template-js" */),
  "component---src-templates-location-template-amp-js": () => import("./../../../src/templates/location-template.amp.js" /* webpackChunkName: "component---src-templates-location-template-amp-js" */),
  "component---src-templates-location-template-js": () => import("./../../../src/templates/location-template.js" /* webpackChunkName: "component---src-templates-location-template-js" */),
  "component---src-templates-news-template-js": () => import("./../../../src/templates/news-template.js" /* webpackChunkName: "component---src-templates-news-template-js" */),
  "component---src-templates-region-template-js": () => import("./../../../src/templates/region-template.js" /* webpackChunkName: "component---src-templates-region-template-js" */),
  "component---src-templates-single-page-template-js": () => import("./../../../src/templates/single-page-template.js" /* webpackChunkName: "component---src-templates-single-page-template-js" */)
}

